<template>
  <b-row class="match-height">
    <b-col
lg="8"
offset="2"
> <b-card
    title="Stock Opname Detail"
  >
      <b-row>
        <b-col cols="12">
          <b-button
            type="button"
            variant="primary"
            class="mr-1"
            @click="previous()"
          >
            <feather-icon
              icon="ArrowLeftIcon"
              size="16"
              class="align-middle"
            />
            Back
          </b-button>
          <div class="devider" />
          </b-col>
      <b-col cols="12">
          <b-form-group
            label="Date *"
            label-cols-md="12"
          >
            <b-form-input
              id="stockOpnameDate"
              v-model="stockOpnameDate"
              readonly="readonly"
            />
          </b-form-group>
        <b-form-group>
          <label for="no_invoice">Description *</label>
            <b-form-input
              id="description"
              v-model="stockOpnameDescription"
              readonly="readonly"
            />
        </b-form-group>
        <b-form-group>
          <label for="no_invoice">Hour *</label>
            <b-form-input
              id="hour"
              v-model="stockOpnameHour"
              readonly="readonly"
            />
        </b-form-group>
      <b-form-group>
        <label for="no_invoice">Status :</label>
        <vue-horizontal-timeline :items="statusItems" />
      </b-form-group>
      <b-form-group>
        <label for="no_invoice">Items :</label>
      </b-form-group>
      <b-form-group>
        <br>
         <div v-if="stockOpnameIsConfirmed===false">
        <b-row>
          <b-col
cols="6"
class="text-center font-weight-bold"
>
            Item name
          </b-col>
          <b-col
cols="6"
class="text-center font-weight-bold"
>
            QTY
          </b-col>
          </b-row>
         <p />
        <b-row
v-for="(item) in items"
:key="item.stock_opname_detail_item_id"
>
        <br>
          <b-col
cols="6"
class="text-center"
>
              <b-list-group>
                <b-list-group-item href="#">
{{ item.item.item_name }} ({{ item.item.item_code }})
</b-list-group-item>
              </b-list-group>
            </b-col>
          <b-col
cols="6"
class="text-center"
>
          <b-form-input
            v-model="item.stock_opname_detail_item_qty"
            class="text-center"
            readonly
/>
          </b-col>
          <b-col cols="12">
            <br>
          </b-col>
          </b-row>
          </div>
         <div v-if="stockOpnameIsConfirmed===true">
          <b-row>
            <b-col
cols="4"
class="text-center font-weight-bold"
>
              Item name
            </b-col>
            <b-col
cols="2"
class="text-center font-weight-bold"
>
              QTY
            </b-col>
            <b-col
cols="2"
class="text-center font-weight-bold"
>
              Curent Stock
            </b-col>
            <b-col
cols="2"
class="text-center font-weight-bold"
>
              Difference
            </b-col>
            <b-col
cols="2"
class="text-center font-weight-bold"
>
              Shelf
            </b-col>
            </b-row>
         <p />
          <b-row
v-for="(item) in items"
:key="item.stock_opname_detail_item_id"
>
          <br>
            <b-col
cols="4"
class="text-center"
>
                <b-list-group>
                  <b-list-group-item href="#">
{{ item.item.item_name }} ({{ item.item.item_code }})
</b-list-group-item>
                </b-list-group>
              </b-col>
            <b-col
cols="2"
class="text-center"
>
            <b-form-input
              v-model="item.stock_opname_detail_item_qty"
              class="text-center"
              readonly
              />
            </b-col>
            <b-col
cols="2"
class="text-center"
>
            <b-form-input
              v-model="item.stock_opname_detail_item_current_stock"
              class="text-center"
              readonly
              />
            </b-col>
            <b-col
cols="2"
class="text-center"
>
            <b-form-input
              v-model="item.stock_opname_detail_item_difference"
              class="text-center"
              readonly
              />
            </b-col>
            <b-col
cols="2"
class="text-center"
>
            <b-form-input
              v-model="item.stock_opname_detail_location"
              class="text-center"
              readonly
              />
            </b-col>
            <b-col cols="12">
              <br>
            </b-col>
            </b-row>
          </div>
      </b-form-group>
        </b-col>
      </b-row>
  </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCard, BListGroup, BListGroupItem,
  } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import { VueHorizontalTimeline } from "vue-horizontal-timeline"
import { ref } from '@vue/composition-api'

const VersionCheck = ''
const items = []
const chosenItem = ref(3)
const chosens = []
const statusItems = []
const supplier = ''
const shipmenBy = ''
const deliveryAddress = ''
const stockOpnameSchedule = ''
const billingAddress = ''
const termOfPayment = ''
const paymentType = ''
const stockOpnameIsConfirmed = false
const stockOpnameIsReceived = false
export default {
  components: {
    VueHorizontalTimeline,
    BListGroup,
    BListGroupItem,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
    stockOpnameIsConfirmed,
    stockOpnameIsReceived,
    paymentType,
    statusItems,
    VersionCheck,
    items,
    chosenItem,
    chosens,
    supplier,
    shipmenBy,
    deliveryAddress,
    stockOpnameSchedule,
    billingAddress,
    termOfPayment,
    }
  },
  mounted() {
      this.getDetail()
  },
  methods: {
  getDetail() {
    const userToken = this.$cookies.get('userToken')
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${userToken}`,
}
    const item = this.$cookies.get('StockOpnameDetail')
    this.modalDetailShow = true
    this.stockOpnameBranch = `${item.branch.branch_name}  (${item.branch.branch_code})`
    this.stockOpnameDate = this.dateSimple(item.stock_opname_date)
    this.stockOpnameHour = item.stock_opname_hour
    this.stockOpnameDescription = item.stock_opname_description
    this.stockOpnameIsConfirmed = item.stock_opname_is_confirmed_by_lab_manager === true ? true : false
    this.stockOpnameType = item.stock_opname_type
    this.stockOpnameNotes = item.stock_opname_notes
    const id = item.stock_opname_id
    const obj1 = {
      title: 'Created',
      content: `Transfer Has been Created at ${this.dateSimple(item.stock_opname_created_time)}`,
      stepCssClass: "has-step-green",
      boxCssClass: "has-color-red",
    }
    const obj2 = {
      title: 'Lab Manager',
      content: item.stock_opname_is_confirmed_by_lab_manager === true ? `Transfer Has been Accepted by Lab Manager` : `Transfer Has not been Accepted By Lab Manager`,
      stepCssClass: item.stock_opname_is_confirmed_by_lab_manager === true ? "has-step-green" : "has-step-red",
    }
    const obj3 = {
      title: 'Administrator',
      content: item.stock_opname_is_confirmed_by_administrator === true ? `Transfer Has been Confirmed By Administrator` : `Transfer Has not been Confirmed by Administrator`,
      stepCssClass: item.stock_opname_is_confirmed_by_administrator === true ? "has-step-green" : "has-step-red",
    }
    this.statusItems = []
    this.statusItems.push(obj1)
    this.statusItems.push(obj2)
    this.statusItems.push(obj3)
    axios
    .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_STOCK_OPNAME_DETAILS}${id}`, { headers })
    .then(response => {
      if (response.data.success === true) {
        this.items = response.data.data
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Fetching Data success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
      } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Fetching Data failed',
              icon: 'CheckIcon',
              variant: 'danger',
            },
          })
      }
      })
  },
  print() {
    const userToken = this.$cookies.get('userToken')
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${userToken}`,
}
    const id = this.stockOpnameId
    axios
    .get(`${process.env.VUE_APP_API_DOWNLOAD_purchase_order}${id}`, { headers })
    .then(response => {
      if (response.data.success === true) {
        console.log(response.data.data)
      } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Download Data failed',
              icon: 'CheckIcon',
              variant: 'danger',
            },
          })
      }
      })
  },
    getOptionLabel(option) {
      return (option && option.label) || ''
    },
    putItem(val) {
      if (this.chosens.length > 0) {
        let same = false
        this.chosens.map(elem => {
              if (elem.value === val.value) {
                same = true
              }
        })
        if (same === false) {
            let item = null
            this.items.map(elem => {
              if (elem.value === val.value) {
                item = { label: elem.label, value: elem.value, qty: 1 }
              }
            })
          this.chosens.push(item)
        }
      } else {
            let item = null
            this.items.map(elem => {
              if (elem.value === val.value) {
                item = { label: elem.label, value: elem.value, qty: 1 }
              }
            })

          this.chosens.push(item)
      }
    },
    applyItem(val) {
      console.log(val.data)
    },
    getOptionKey(option) {
      return (option && option.value) || ''
    },
    inputSearch(search) {
      console.log(search)
    },
    removeRow(val) {
      this.chosens = this.chosens.filter(obj => (obj.value !== val))
    },
    dateFormat(value, format = 'MM/DD/YYYY') {
        return moment(String(value)).format(format)
    },
  dateSimple(value, format = 'YYYY-MM-DD') {
      let dateRet = ''
      if (value !== null) {
        dateRet = moment(String(value)).format(format)
      } else { dateRet = null }
      return dateRet
  },
  previous() {
        this.$router.push({ name: 'apps-stock-opname-list' })
  },
    numberFormat(value) {
      return parseFloat(value).toFixed(2)
    },
  },
}
</script>